<template>
  <div v-if="banners.length > 1" class="row justify-end">
    <div
      class="select-button text-weight-bold text-blue-11"
      @click="open('bottom')"
    >
      {{ $t("landing_page.select_banner") }}
    </div>
  </div>
  <q-dialog v-model="dialog" :position="position">
    <q-card class="banner-dialog">
      <q-card-section class="column">
        <q-img
          class="draw-banner-img"
          :src="$q.platform.is.mobile ? draw.path_mobile : draw.path_desktop"
        />
        <div class="q-ma-lg row wrap justify-center full-width bg-white">
          <div
            class="banner-mini-item default-shadow"
            v-for="(banner, key) in banners"
            :key="`banner-${key}`"
            @click="bannerClicked(banner)"
            :class="{ selected: isSelected(banner) }"
          >
            <q-img
              width="100%"
              fit="cover"
              height="100%"
              :src="
                $q.platform.is.mobile ? banner.path_mobile : banner.path_desktop
              "
            />
          </div>
        </div>
        <div class="row justify-center q-ma-lg">
          <q-btn
            @click="apply()"
            color="dark"
            :label="$t('global.apply')"
            class="banner-apply-button q-pa-sm"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
import { notifyInfo } from "@/shared/helpers/notify";
import { find, filter, map } from "lodash";
import { ref } from "vue";

export default {
  name: "SelectBanners",

  emits: ["changed"],

  props: {
    banners: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
  },

  setup(props, root) {
    const dialog = ref(false),
      position = ref("top"),
      draw = ref(props.banners[0]),
      bannersSelected = ref([]);

    if (props.selected && props.selected.length) {
      bannersSelected.value = filter(props.banners, (b) => {
        return !!find(
          props.selected,
          (s) => s.lp_template_carousel_id === b.id
        );
      });
    }

    const open = (pos) => {
        if (props.banners.length === 0) {
          notifyInfo("landing_page.no_lp_carousels");
        }
        position.value = pos;
        dialog.value = true;
      },
      bannerClicked = (banner) => {
        let hasBanner = !!find(
          bannersSelected.value,
          (b) => b.id === banner.id
        );
        if (hasBanner) {
          bannersSelected.value = filter(
            bannersSelected.value,
            (b) => b.id !== banner.id
          );
          if (draw.value.id === banner.id) {
            draw.value = props.banners[0];
          }
        } else {
          bannersSelected.value.push(banner);
          draw.value = banner;
        }
      };

    const isSelected = (banner) =>
      !!find(bannersSelected.value, (b) => b.id === banner.id);

    const apply = () => {
      root.emit(
        "changed",
        map(bannersSelected.value, (b) => {
          return b.id;
        })
      );
      dialog.value = false;
    };

    return {
      dialog,
      position,
      draw,
      isSelected,
      open,
      bannerClicked,
      apply,
    };
  },
};
</script>

<style scoped lang="scss">
.banner-dialog {
  width: 100%;
  max-width: 900px;
  .banner-apply-button {
    width: 100%;
    max-width: 200px;
  }
  .draw-banner-img {
    height: 200px;
  }
  .banner-mini-item {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 10px;
    overflow: hidden;
    opacity: 0.5;
    &.selected {
      opacity: 1;
      border: 5px solid #24e087;
    }
  }
}
.select-button {
  width: fit-content;
  padding: 4px;
  border-radius: 2px;
  cursor: pointer;
}
</style>
