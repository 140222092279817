<template>
  <div
    class="qs-mb-lg qs-mr-lg paid-total default-rounded row flex-center justify-between"
    :class="{ 'default-shadow': !skeleton }"
  >
    <span class="text-bold" :style="skeleton ? { color: '#d4d3de' } : {}">{{
      title
    }}</span>
    <span class="text-weight-thin row no-wrap flex-center">
      <q-icon
        class="q-ml-md q-mr-md"
        :name="icon"
        :style="skeleton ? { color: '#d4d3de' } : {}"
        size="1.5em"
      ></q-icon>
      <q-skeleton
        v-if="skeleton"
        animation="pulse-x"
        height="2.23em"
        type="QBadge"
      />
      <div v-if="!skeleton" class="money">{{ value }}</div>
    </span>
  </div>
</template>

<script>
export default {
  name: "paidTotalItem",

  props: {
    icon: {
      type: String,
      default: "trending_up",
    },
    title: {
      type: String,
    },
    value: {
      type: String,
    },
    skeleton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.money {
  font-size: 1.5em;
}

.paid-total {
  padding: 5px 10px;
}

.mobile .paid-total {
  box-shadow: none !important;
}
</style>
