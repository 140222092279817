<template>
  <div class="row justify-center q-mb-lg q-mt-lg q-mr-sm q-ml-sm">
    <search-bar
      v-if="!my_lists_component"
      @updated="onSearchValueUpdated"
      :expanded="true"
      :disabled="in_request"
      :placeholder="$t('products.search_product')"
    />
  </div>
  <div v-if="!my_lists_component" class="row" :class="$q.platform.is.mobile ? '' : 'q-mr-xl q-ml-xl'">
    <span class="alert-label" v-html="$t('products.alert_label')" />
  </div>
  <products-skeleton v-if="in_request"></products-skeleton>
  <products v-if="!in_request" :products="products" />
  <card-info
    v-if="products.length === 0 && !in_request"
    :title="$t('products.no_products_title')"
    :subtitle="$t('products.no_products_subtitle')"
  />
  <div class="q-pa-lg flex flex-center">
    <pagination
      :disabled="in_request"
      :resource="pagination"
      :repository="fetch"
    />
  </div>
</template>

<script>
import ProductsSkeleton from "@/modules/main/pages/products/components/productsSkeleton";
import CardInfo from "@/modules/main/pages/me-store/components/card-info";
import Products from "@/modules/main/pages/products/components/products";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import Pagination from "@/modules/main/components/pagination";
import SearchBar from "@/modules/main/components/search-bar";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("products");

export default {
  name: "FavoritesProductsContainer",

  emits: ["noFavoriteProducts"],

  props: {
    my_lists_component: {
      type: Boolean,
      default: false
    }
  },  

  components: { Pagination, CardInfo, Products, ProductsSkeleton, SearchBar },

  setup(props, root) {
    const { products, pagination } = useGetters({
        products: "getFavoritesProducts",
        pagination: "getFavoritesProductsPagination",
      }),
      { fetchFavoriteProductsAction, setFavoriteFilterValue } = useActions([
        "setFavoriteFilterValue",
        "fetchFavoriteProductsAction",
      ]),
      in_request = ref(true),
      noTabChange = ref(false);

    const fetch = (payload = {}) => {
        in_request.value = true;
        fetchFavoriteProductsAction(payload).finally(() => {
          in_request.value = false;
          if (products.value.length === 0 && noTabChange.value === false)
            root.emit("noFavoriteProducts", null);
        });
      },
      onSearchValueUpdated = (value) => {
        noTabChange.value = true;
        setFavoriteFilterValue({ filter: value });
        fetch();
      };

    fetch();

    return {
      products,
      pagination,
      in_request,
      fetch,
      onSearchValueUpdated,
    };
  },
};
</script>

<style lang="scss" scoped>
.alert-label {
  font-size: 0.9em;
  font-weight: bolder;
  padding: 0 5em;
  color: rgb(80, 80, 80);
}
.mobile {
  .alert-label {
    padding: 0;
  }
}
</style>
