import script from "./index.vue?vue&type=script&setup=true&lang=js"
export * from "./index.vue?vue&type=script&setup=true&lang=js"

import "./index.vue?vue&type=style&index=0&id=0f05fb98&lang=scss"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QBadge,QTabs,QTab,QTabPanels,QTabPanel});
