<template>
  <div class="row q-ma-sm justify-between">
    <page-title
      title="landing_page.page_title"
      class="width-fit-content-flex"
      video_url="https://www.youtube.com/embed/UBRottamhfA"
    />

    <div class="q-mr-sm q-ml-md" v-if="getHasCheckout">
      <div class="col-12 col-md-3">
        <q-btn
          dense
          round
          flat
          icon="shopping_bag"
          style="font-size: 15pt; cursor: pointer"
          @click="openCheckoutFn"
          :class="{
            'q-mt-sm': $q.screen.width < 800,
            'q-mt-md': $q.screen.width > 800,
          }"
        >
          <q-badge
            color="red"
            floating
            transparent
            v-if="getCheckoutData.length"
          >
            {{ getCheckoutData.length }}
          </q-badge>
        </q-btn>
      </div>
    </div>

    <div class="q-mr-sm q-ml-sm" v-if="getLandingPageLink">
      <div class="col-12 col-md-3">
        <q-btn
          dense
          flat
          color="blue"
          :label="$t('landing_page.seen_my_landing_page')"
          @click="accesLink(`${getLandingPageLink + user_slug}`)"
          style="font-size: 10pt; cursor: pointer"
          :class="{
            'q-mt-sm': $q.screen.width < 800,
            'q-mt-lg': $q.screen.width > 800,
          }"
        />
      </div>
    </div>
  </div>

  <landing-page-editor />

  <q-tabs
    v-model="tab"
    dense
    class="text-red custom-lp-tab"
    active-color="black"
    align="center"
  >
    <q-tab name="products" :label="$t('products.my_products')" />
    <q-tab name="favorites" :label="$t('products.my_favorites')" />
  </q-tabs>
  <q-tab-panels
    :keep-alive="true"
    class="bg-transparent"
    v-model="tab"
    animated
  >
    <q-tab-panel
      :class="{ 'no-padding no-margin': $q.platform.is.mobile }"
      name="products"
    >
      <store-products-container />
    </q-tab-panel>
    <q-tab-panel
      :class="{ 'no-padding no-margin': $q.platform.is.mobile }"
      name="favorites"
    >
      <favorites-products-container
        @no-favorite-products="changeTabToProducts"
      />
    </q-tab-panel>
  </q-tab-panels>
</template>

<script setup>
import FavoritesProductsContainer from "@/modules/main/pages/products/components/FavoritesProductsContainer";
import StoreProductsContainer from "@/modules/main/pages/products/components/storeProductsContainer";
import LandingPageEditor from "@/modules/main/pages/landing-pages/components/landingPageEditor";
import { useGetters, createNamespacedHelpers } from "vuex-composition-helpers";
import pageTitle from "@/modules/main/components/page-title";
import { ref, onMounted } from "vue";

const productsState = createNamespacedHelpers("products");

const tab = ref("favorites");
const showCart = ref(false);

const changeTabToProducts = () => {
  tab.value = "products";
};

const { user_slug } = useGetters({
  user_slug: "auth/userSlug",
});

const { getCheckoutData, getHasCheckout, getLandingPageLink } =
  productsState.useGetters([
    "getCheckoutData",
    "getHasCheckout",
    "getLandingPageLink",
  ]);

const { openCheckoutSide, fetchCheckoutParam, fetchVerifyLandingPageLink } =
  productsState.useActions([
    "openCheckoutSide",
    "fetchCheckoutParam",
    "fetchVerifyLandingPageLink",
  ]);

function openCheckoutFn() {
  showCart.value = true;
  openCheckoutSide()
}

function accesLink(url) {
  window.open(url, "_blank");
}

onMounted(() => {
  fetchVerifyLandingPageLink({ keys: ["partner_landing_page_link"] });
  fetchCheckoutParam({
    keys: ["checkout_rules", "generate_checkout_link"],
  });
});
</script>

<style lang="scss">
.custom-lp-tab {
  width: 100%;
  display: flex;
  justify-content: center;
  .q-tabs__content {
    background-color: #cfcfcf;
    border-radius: 10px;
    max-width: fit-content;
    .q-tab__indicator {
      display: none;
    }
    .q-tab__label {
      color: #454545;
    }
    .q-tab.q-tab--active {
      background-color: #222222;
      border-radius: 15px;
      .q-tab__label {
        color: #d2d2d2;
      }
    }
  }
}
</style>
