<template>
  <div class="search-wrapper" :class="{ active: active || expanded }">
    <div class="input-holder">
      <label>
        <input
          type="text"
          class="search-input"
          v-model="searchValue"
          :placeholder="placeholder"
          @keyup="onDebouncedChanged"
        />
      </label>
      <button
        v-if="showCleanableButton()"
        class="search-icon cleanable"
        @click="onClear"
      >
        <q-icon name="close" size="sm" />
      </button>
      <button v-else class="search-icon" @click="onOpen">
        <span></span>
      </button>
    </div>
    <span v-if="!expanded" class="close" @click="onClose"> </span>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { debounce } from "lodash";

export default {
  name: "SearchBar",

  emits: ["updated"],

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    clearWatch: {
      type: Number,
    },
  },

  setup(props, { emit }) {
    const active = ref(false),
      searchValue = ref(""),
      lastEmitedValue = ref("");

    const onClose = () => {
        active.value = false;
        searchValue.value = "";
        changedValue();
      },
      onClear = () => {
        searchValue.value = "";
        changedValue();
      };

    const onOpen = () => {
        props.disabled ? (active.value = false) : (active.value = true);
      },
      changedValue = () => {
        if (lastEmitedValue.value !== searchValue.value) {
          emit("updated", searchValue.value);
          lastEmitedValue.value = searchValue.value;
        }
      },
      showCleanableButton = () =>
        props.expanded === true && searchValue.value.length > 0;

    const onDebouncedChanged = debounce(changedValue, 1000);

    watch(() => props.clearWatch, onClear);

    return {
      active,
      searchValue,
      onDebouncedChanged,
      onClose,
      onClear,
      onOpen,
      showCleanableButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.search-wrapper .close {
  visibility: hidden;
}

.search-wrapper.active .close {
  visibility: visible;
}
.search-wrapper {
  position: relative;
  width: fit-content;
}
.search-wrapper.active {
  width: 90%;
}
.mobile {
  .search-wrapper.active {
    width: 100%;
  }
}
.search-wrapper .input-holder {
  width: 40px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
  border-radius: 6px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.layout-mobile .uk-header-bar {
  margin: 4vh 0;
}
.search-wrapper.active .input-holder {
  width: 100%;
}

.search-wrapper .input-holder .search-input {
  padding: 15px;
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #060606;
  transform: translate(0, 0px);
  transition: all 0.3s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.3s;
  background: #ffffff;
}
.search-wrapper.active .input-holder .search-input {
  opacity: 1;
  transform: translate(0, 0px);
}
.search-wrapper .input-holder .search-icon {
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 6px;
  background: #fff;
  padding: 0;
  outline: none;
  position: relative;
  z-index: 2;
  float: right;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.search-wrapper .input-holder .search-icon.cleanable > span {
  display: none;
}

.search-wrapper.active .input-holder .search-icon {
  width: 40px;
  height: 40px;
  margin: 0;
  background: #ffffff;
  border-radius: 30px;
}
.search-wrapper .input-holder .search-icon span {
  width: 22px;
  height: 22px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  transform: rotate(45deg);
  transition: all 0.4s cubic-bezier(0.65, -0.6, 0.24, 1.65);
}
.search-wrapper.active .input-holder .search-icon span {
  transform: rotate(-45deg);
}
.search-wrapper .input-holder .search-icon span::before,
.search-wrapper .input-holder .search-icon span::after {
  position: absolute;
  content: "";
}
.search-wrapper .input-holder .search-icon span::before {
  width: 2px;
  height: 11px;
  left: 7px;
  top: 13px;
  border-radius: 2px;
  background: #222222;
}
.search-wrapper .input-holder .search-icon span::after {
  width: 14px;
  height: 14px;
  left: 0;
  top: 0;
  border-radius: 16px;
  border: 2px solid #222222;
}
.search-wrapper .close {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 20px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: all 0.3s cubic-bezier(0.285, -0.45, 0.935, 0.11);
  transition-delay: 0.2s;
}
.search-wrapper.active .close {
  right: -50px;
  transform: rotate(45deg);
  transition: all 0.6s cubic-bezier(0, 0.105, 0.035, 1.57);
  transition-delay: 0.5s;
}
.search-wrapper .close::before,
.search-wrapper .close::after {
  position: absolute;
  content: "";
  background: #222222;
  border-radius: 2px;
}
.search-wrapper .close::before {
  width: 1px;
  height: 25px;
  left: 12px;
  top: 1px;
}
.search-wrapper .close::after {
  width: 25px;
  height: 1px;
  left: 0;
  top: 12px;
}
</style>
