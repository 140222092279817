<template>
  <div class="row justify-center q-mb-lg q-mt-lg q-mr-sm q-ml-sm">
    <search-bar
      @updated="onSearchValueUpdated"
      :expanded="true"
      :disabled="in_request"
      :placeholder="$t('products.search_custom')"
    />
  </div>
  <div class="row" :class="$q.platform.is.mobile ? '' : 'q-mr-xl q-ml-xl'">
    <span class="alert-label">{{ $t("products.alert_label") }}</span>
  </div>
  <products-skeleton v-if="in_request"></products-skeleton>
  <products v-if="!in_request" :products="products" />
  <card-info
    v-if="products.length === 0 && !in_request"
    :title="$t('products.no_products_title')"
    :subtitle="$t('products.no_products_subtitle')"
  ></card-info>
  <div class="q-pa-lg flex flex-center">
    <pagination
      :disabled="in_request"
      :resource="pagination"
      :repository="fetch"
    />
  </div>
</template>

<script>
import ProductsSkeleton from "@/modules/main/pages/products/components/productsSkeleton";
import Products from "@/modules/main/pages/products/components/products";
import CardInfo from "@/modules/main/pages/me-store/components/card-info";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import SearchBar from "@/modules/main/components/search-bar";
import Pagination from "@/modules/main/components/pagination";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("products");

export default {
  name: "StoreProductsContainer",

  components: { Pagination, CardInfo, Products, ProductsSkeleton, SearchBar },

  setup() {
    const { products, pagination } = useGetters({
        products: "getStoreProducts",
        pagination: "getStoreProductsPagination",
      }),
      { fetchStoreProductsAction, setStoreFilterValue } = useActions([
        "setStoreFilterValue",
        "fetchStoreProductsAction",
      ]),
      in_request = ref(true);

    const fetch = (payload = {}) => {
        in_request.value = true;
        fetchStoreProductsAction(payload).finally(
          () => (in_request.value = false)
        );
      },
      onSearchValueUpdated = (value) => {
        setStoreFilterValue({ filter: value });
        fetch();
      };

    fetch();

    return {
      in_request,
      products,
      pagination,
      fetch,
      onSearchValueUpdated,
    };
  },
};
</script>

<style lang="scss" scoped>
.alert-label {
  font-size: 0.9em;
  font-weight: bolder;
  padding: 0 5em;
  color: rgb(80, 80, 80);
}
.mobile {
  .alert-label {
    padding: 0;
  }
}
</style>
