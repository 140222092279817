<template>
  <div class="q-pa-md row justify-center">
    <div class="full-width" style="max-width: 1200px">
      <div v-if="!inRequest">
        <select-banners
          :banners="available_banners"
          :selected="selected_banners"
          @changed="onBannerSelected"
        />
        <carousel-banner :banners="getBanners()" v-if="getBanners().length" />
      </div>
      <q-skeleton v-else width="100%" height="350px" />
    </div>
  </div>
</template>

<script>
import CarouselBanner from "@/modules/main/pages/landing-pages/components/carouselBanner";
import SelectBanners from "@/modules/main/pages/landing-pages/components/selectBanners";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { notifyInfo } from "@/shared/helpers/notify";
import { filter, find } from "lodash";
import { ref } from "vue";

const { useGetters, useActions } = createNamespacedHelpers("landing_page");

export default {
  name: "LandingPageEditor",

  components: { CarouselBanner, SelectBanners },

  setup() {
    const { fetchLpProducts, storeLpBannersSelected } = useActions([
        "fetchLpProducts",
        "storeLpBannersSelected",
      ]),
      { available_banners, selected_banners } = useGetters({
        available_banners: "getAvailableBanners",
        selected_banners: "getSelectedBanners",
      }),
      inRequest = ref(true);

    fetchLpProducts().finally(() => (inRequest.value = false));

    const onBannerSelected = (ids) => {
        inRequest.value = true;
        storeLpBannersSelected(ids)
          .then(() => {
            notifyInfo("notify.success");
          })
          .catch(() => {
            notifyInfo("notify.success");
          })
          .finally(() => (inRequest.value = false));
      },
      getBanners = () => {
        return filter(available_banners.value, (b) => {
          return !!find(
            selected_banners.value,
            (s) => s.lp_template_carousel_id === b.id
          );
        });
      };

    return {
      inRequest,
      available_banners,
      selected_banners,
      getBanners,
      onBannerSelected,
    };
  },
};
</script>
